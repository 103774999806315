import { useState } from 'react';
import { Formik, Form } from 'formik';
import { Box, Button, Grid, Typography } from '@mui/material';
import AppContainer from '../shared/AppContainer';
import AppHeader from '../shared/AppHeader';
import AppDialog from "../shared/AppDialog";
import PasswordDialog from "./PasswordDialog";
import SuccessAnimation from "../../assets/Lotties/SuccessAnimation";
import {
    validationSchema,
    PhoneNumberInput,
    StateInput,
    ZipCodeInput,
    FormTextField,
} from '../../utils/validationAndInputs';
import FormIcon from "../../assets/Icons/FormIcon";
import {useNavigate} from "react-router-dom";
import {useApi} from "../../contexts/ApiProvider";

const initialValues = {
    fullName: '',
    company_name: '',
    address_line_1: '',
    city: '',
    state_abbreviation: '',
    zipcode: '',
    phone_number: '',
    email: '',
    confirmEmail: ''
};

const StepNavigation = ({ activeStep, handleBack, handleNext, isLastStep }) => (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
        <Button
            disabled={activeStep === 0}
            onClick={handleBack}
            variant="outlined"
            sx={{
                borderColor: 'gray',
                color: 'gray',
                '&:hover': {
                    borderColor: 'gray',
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
                '&:active': {
                    borderColor: 'gray',
                },
                '&:focus': {
                    borderColor: 'gray',
                },
            }}
        >
            Back
        </Button>
        {isLastStep ? (
            <Button
                type="submit"
                variant="outlined"
                sx={{
                    borderColor: '#4EB6B5',
                    color: '#4EB6B5',
                    '&:hover': {
                        borderColor: '#4EB6B5',
                        backgroundColor: 'rgba(255, 0, 0, 0.04)',
                    },
                    '&:active': {
                        borderColor: '#4EB6B5',
                    },
                    '&:focus': {
                        borderColor: '#4EB6B5',
                    },
                }}
            >
                Submit
            </Button>
        ) : (
            <Button
                onClick={handleNext}
                variant="outlined"
                sx={{
                    borderColor: '#4EB6B5',
                    color: '#4EB6B5',
                    '&:hover': {
                        borderColor: '#4EB6B5',
                        backgroundColor: 'rgba(255, 0, 0, 0.04)',
                    },
                    '&:active': {
                        borderColor: '#4EB6B5',
                    },
                    '&:focus': {
                        borderColor: '#4EB6B5',
                    },
                }}
            >
                Next
            </Button>
        )}
    </Box>
);

const RegistrationForm = () => {
    const navigate = useNavigate();
    const api = useApi();
    const [activeStep, setActiveStep] = useState(0);
    const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [passwordOpen, setPasswordOpen] = useState(true);


    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handlePasswordSubmit = () => {
        setIsAuthorized(true);
    };

    const handleFinish = () => {
        setOpenSuccessDialog(false);
        navigate('/register');
    };

    const handleFormSubmit = async (values) => {
        const [first_name, last_name] = values.fullName.split(' ');
        const formData = {
            ...values,
            first_name,
            last_name,
            email: values.confirmEmail
        };
        delete formData.fullName;
        delete formData.confirmEmail;
        console.log(formData);

        try {
            const response = await api.post('/con_registration', formData);
            console.log(response);
            if (response.status === 200) {
                localStorage.setItem('contractorToken', response.body.contractor_token);
                setOpenSuccessDialog(true);
            } else {
                console.error('Contractor form submission failed');
            }
        } catch (error) {
            console.error('Contractor form submission failed', error);
        }
    };


    const renderStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <Box sx={{ mt: 4 }}>
                        <Typography variant="h6" gutterBottom>Personal Information:</Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormTextField name="fullName" label="Full Name" />
                            </Grid>
                            <Grid item xs={12}>
                                <FormTextField name="company_name" label="Company Name" />
                            </Grid>
                        </Grid>
                    </Box>
                );
            case 1:
                return (
                    <Box sx={{ mt: 4 }}>
                        <Typography variant="h6" gutterBottom>Geography:</Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormTextField name="address_line_1" label="Address" />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormTextField name="city" label="City" />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <StateInput name="state_abbreviation" label="State" />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <ZipCodeInput name="zipcode" label="ZIP Code" />
                            </Grid>
                        </Grid>
                    </Box>
                );
            case 2:
                return (
                    <Box sx={{ mt: 4 }}>
                        <Typography variant="h6" gutterBottom>Contact Information:</Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <PhoneNumberInput name="phone_number" label="Phone Number" />
                            </Grid>
                            <Grid item xs={12}>
                                <FormTextField name="email" label="Email" />
                            </Grid>
                            <Grid item xs={12}>
                                <FormTextField name="confirmEmail" label="Confirm Email" />
                            </Grid>
                        </Grid>
                    </Box>
                );
            default:
                return <div>Not Found</div>;
        }
    };


    const handleDialogClose = () => {
        setPasswordOpen(false);
    };

    return (
        <AppContainer>
            <AppHeader title="Contractor Onboarding" backButtonRoute="/" />
            <PasswordDialog
                open={passwordOpen}
                onClose={handleDialogClose}
                onPasswordSubmit={handlePasswordSubmit}
            />
            {isAuthorized && (
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleFormSubmit}
                >
                    {({ handleSubmit }) => (
                        <Form onSubmit={handleSubmit}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mt: 4, mb: 5 }}>
                                <FormIcon />
                                <Typography fontWeight="600" variant="subtitle1" textAlign="center" >
                                    Lets get started. Simply input your information and continue.
                                </Typography>
                            </Box>
                            {renderStepContent(activeStep)}
                            <StepNavigation
                                activeStep={activeStep}
                                handleBack={handleBack}
                                handleNext={handleNext}
                                isLastStep={activeStep === 2}
                            />
                            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                                {[0, 1, 2].map((step) => (
                                    <Box
                                        key={step}
                                        sx={{
                                            width: 10,
                                            height: 10,
                                            borderRadius: '50%',
                                            backgroundColor: activeStep === step ? '#4EB6B5' : '#959292',
                                            mx: 1,
                                        }}
                                    />
                                ))}
                            </Box>
                        </Form>
                    )}
                </Formik>
            )}
            <AppDialog
                open={openSuccessDialog}
                title="You are all set!"
                handleConfirm={handleFinish}
                confirmText="Finish"
            >
                <SuccessAnimation />
                <Typography variant="subtitle2">
                    Your Anesi trainer will have further information for you!
                </Typography>
            </AppDialog>
        </AppContainer>
    );
};

export default RegistrationForm;
