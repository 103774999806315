import React from 'react';
import {Box, Typography, useMediaQuery} from "@mui/material";
import styled from "@emotion/styled";
import AppCard from "../shared/AppCard";
import {convertAndDetermineColor} from "../../utils/TempUtils";
import TimelineIcon from "@mui/icons-material/Timeline";
import {useNavigate} from "react-router-dom";

const CardContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
});

const UnitInfo = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '1em',
    marginTop: '1em',
});

const InvertedCard = styled(AppCard)({
    backgroundColor: '#37589D',
    color: 'white',
    borderColor: 'transparent',
});

const AppCardExtra = styled(AppCard)({
    '& .MuiCardContent-root:last-child': {
        padding: 15,
    },
})

function UnitStatus({unitData, isMetric, contractor, unitType}) {
    const navigate = useNavigate();
    const isSmallScreen = useMediaQuery('(max-width:390px)');
    const isVerySmallScreen = useMediaQuery('(max-width:359px)');
    const fontSize = isVerySmallScreen ? '0.85em' : isSmallScreen ? '1em' : '1.25em';
    const handlePlotIcon = (path, title) => {
        navigate(`${path}?title=${title}`);
    };

    return (
        <UnitInfo>
            <CardContainer>
                <Typography mb={0.5} fontWeight="700" color="#959292">Condition:</Typography>
                <InvertedCard>
                    {unitType === 'GAHP' && (
                        <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                            <Box>
                                <Typography fontWeight="600" style={{ fontSize }}>Status</Typography>
                            </Box>
                            <Box display="flex" alignItems="center">
                                <Typography fontWeight="700" style={{ fontSize, }}>{unitData[67][0].replace('GAHP', '')}</Typography>
                            </Box>
                        </Box>
                    )}
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Box>
                            <Typography fontWeight="600" style={{ fontSize}}>Operating Mode</Typography>
                        </Box>
                        <Box display="flex" alignItems="center">
                            <Typography fontWeight="700" style={{ fontSize,}}>{unitType === 'GAHP' ? unitData[66][0].replace('GAHP', '') : unitData[65][0].replace('AHU', '')}</Typography>
                        </Box>
                    </Box>
                    {unitType === 'AHU' && (
                        <Box display="flex" alignItems="center" justifyContent="space-between" mt={2}>
                            <Box>
                                <Typography fontWeight="600" style={{ fontSize }}>Blower Status</Typography>
                            </Box>
                            <Box display="flex" alignItems="center">
                                <Typography fontWeight="700" style={{ fontSize,}}>{unitData[57][0]}</Typography>
                            </Box>
                        </Box>
                    )}
                </InvertedCard>
            </CardContainer>
            <CardContainer>
                <Typography mb={0.5} fontWeight="700" color="#959292">Temperatures:</Typography>
                <AppCardExtra>
                    {unitType === 'GAHP' ? (
                        <>
                            <Box display="flex" alignItems="center" justifyContent="space-between" mb={1.5}>
                                <Box>
                                    <Typography fontWeight="500" style={{ fontSize }}>{contractor ? 'Ambient' : 'Outdoor Air'}</Typography>
                                </Box>
                                <Box display="flex" alignItems="center">
                                    {(() => {
                                        const { convertedValue, color } = convertAndDetermineColor(unitData[10][0], isMetric, true);
                                        return <Typography fontWeight="700" style={{ fontSize, marginRight: '1em', color }}>{convertedValue}</Typography>;
                                    })()}
                                    {contractor ?
                                        <Box ml={2}>
                                            <TimelineIcon onClick={() => handlePlotIcon('plot', 'Ambient')} style={{ color: '#4EB6B5' }} />
                                        </Box>
                                        : <Box />
                                    }
                                </Box>
                            </Box>
                            <Box display="flex" alignItems="center" justifyContent="space-between" mb={1.5}>
                                <Box>
                                    <Typography fontWeight="500" style={{ fontSize }}>Hydronic Supply</Typography>
                                </Box>
                                <Box display="flex" alignItems="center">
                                    {(() => {
                                        const { convertedValue, color } = convertAndDetermineColor(unitData[12][0], isMetric);
                                        return <Typography fontWeight="700" style={{ fontSize, marginRight: '1em', color }}>{convertedValue}</Typography>;
                                    })()}
                                    {contractor ?
                                        <Box ml={2}>
                                            <TimelineIcon onClick={() => handlePlotIcon('plot', 'Hydronic Supply')} style={{ color: '#4EB6B5' }} />
                                        </Box>
                                        : <Box />
                                    }
                                </Box>
                            </Box>
                            {contractor && (
                                <Box display="flex" alignItems="center" justifyContent="space-between" mb={1.5}>
                                    <Box>
                                        <Typography fontWeight="500" style={{ fontSize }}>Hydronic Return</Typography>
                                    </Box>
                                    <Box display="flex" alignItems="center">
                                        {(() => {
                                            const { convertedValue, color } = convertAndDetermineColor(unitData[16][0], isMetric);
                                            return <Typography fontWeight="700" style={{ fontSize, marginRight: '1em', color }}>{convertedValue}</Typography>;
                                        })()}
                                        <Box ml={2}>
                                            <TimelineIcon onClick={() => handlePlotIcon('plot', 'Hydronic Return')} style={{ color: '#4EB6B5' }} />
                                        </Box>
                                    </Box>
                                </Box>
                            )}
                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                <Box>
                                    <Typography fontWeight="500" style={{ fontSize }}>Hydronic Flow</Typography>
                                </Box>
                                <Box display="flex" alignItems="center">
                                    <Typography fontWeight="700" style={{ fontSize, marginRight: '1em' }}>{unitData[17][0]} GPM</Typography>
                                    {contractor ?
                                        <Box ml={2}>
                                            <TimelineIcon onClick={() => handlePlotIcon('plot', 'Hydronic Flow')} style={{ color: '#4EB6B5' }} />
                                        </Box>
                                        : <Box />
                                    }
                                </Box>
                            </Box>
                        </>
                    ) : (
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                            <Box>
                                <Typography fontWeight="600" style={{ fontSize}}>Supply Air</Typography>
                            </Box>
                            <Box display="flex" alignItems="center">
                                {(() => {
                                    const { convertedValue, color } = convertAndDetermineColor(unitData[42][0], isMetric);
                                    return <Typography fontWeight="700" style={{ fontSize, marginRight: '1em', color }}>{convertedValue}</Typography>;
                                })()}
                                {contractor ?
                                    <Box ml={2}>
                                        <TimelineIcon onClick={() => handlePlotIcon('plot', 'Supply Air')} style={{ color: '#4EB6B5' }} />
                                    </Box>
                                    : <Box />
                                }
                            </Box>
                        </Box>
                    )}
                </AppCardExtra>
            </CardContainer>
        </UnitInfo>
    );
}

export default UnitStatus;





